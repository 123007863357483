'use client';

import { StatisticTilesComponent } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import styles from './StatisticTiles.module.scss';
import AnimatedTitle from '../AnimatedTitle';
import Ellipse, { EllipseVariants } from '../Ellipse';
import deviceScreenSize from '../../store/deviceScreenSize';

const StatisticTiles = ({ data }: { data: StatisticTilesComponent }) => {
  const deviceSize = useAtomValue(deviceScreenSize);
  const [bgTopPosition, setBgTopPosition] = useState(0);
  const tilesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tilesRef.current) {
      const tilesRect = tilesRef.current.getBoundingClientRect();

      setBgTopPosition(tilesRect.top + window.scrollY);
    }
  }, [deviceSize]);

  return (
    <>
      <section
        className={styles.statisticSection}
        ref={tilesRef}
      >
        <div className={classNames(styles.content, 'container')}>
          <h2 className={classNames(styles.title, 'title3')}><AnimatedTitle title={data.title} /></h2>
          <div className={styles.tiles}>
            <div className={classNames(styles.description, styles.tilesDescription)}>{data.description}</div>
            {data.metrics.map(metric => (
              <div
                className={styles.tile}
                key={metric.id}
              >
                <p className={styles.value}>{metric.title}</p>
                <p className={styles.description}>{metric.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div
        className={styles.tilesBg}
        style={{ top: bgTopPosition }}
      >
        <Ellipse
          variant={EllipseVariants.DRAW}
          size={460}
          className={styles.largestCircle}
        />
        <Ellipse
          variant={EllipseVariants.DRAW}
          size={320}
          className={styles.mediumCircle}
        />
        <Ellipse
          variant={EllipseVariants.DRAW}
          size={200}
          className={styles.smallCircle}
        />
        <Ellipse
          variant={EllipseVariants.DRAW}
          size={124}
          className={styles.smallestCircle}
        />
        <Ellipse
          variant={EllipseVariants.NOISE}
          size={72}
          className={styles.noise}
        />
        <Ellipse
          variant={EllipseVariants.GRADIENT}
          size={16}
          className={styles.planetBig}
        />
        <Ellipse
          variant={EllipseVariants.GRADIENT}
          size={4}
          className={styles.planetSmall}
        />
      </div>
    </>
  );
};

export default StatisticTiles;
