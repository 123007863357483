import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import styles from './WebDesign.module.scss';
import { theme } from '../../../../../store/theme';

const basePath = '/media/subservice-animations/web-design';

const WebDesign = ({ active }: { active: boolean }) => {
  const appTheme = useAtomValue(theme);

  return (
    <div className={classNames(styles.wrapper, {
      [styles.active]: active,
    })}
    >
      <svg className={styles.window_init}>
        <use
          xlinkHref={`${basePath}/init-window-${appTheme}.svg#subservices/web-design/init-window-${appTheme}`}
          href={`${basePath}/init-window-${appTheme}.svg#subservices/web-design/init-window-${appTheme}`}
        />
      </svg>
      <svg className={styles.window}>
        <use
          xlinkHref={`${basePath}/window-${appTheme}.svg#subservices/web-design/window-${appTheme}`}
          href={`${basePath}/window-${appTheme}.svg#subservices/web-design/window-${appTheme}`}
        />
      </svg>
      <svg className={classNames(styles.crossed_rectangle, styles.animated)}>
        <use
          xlinkHref={`${basePath}/rectangle-crossed-${appTheme}.svg#subservices/web-design/rectangle-crossed-${appTheme}`}
          href={`${basePath}/rectangle-crossed-${appTheme}.svg#subservices/web-design/rectangle-crossed-${appTheme}`}
        />
      </svg>
      <svg className={classNames(styles.squares, styles.animated)}>
        <use
          xlinkHref={`${basePath}/squares-${appTheme}.svg#subservices/web-design/squares-${appTheme}`}
          href={`${basePath}/squares-${appTheme}.svg#subservices/web-design/squares-${appTheme}`}
        />
      </svg>
      <svg className={classNames(styles.rectangle_small_first, styles.animated)}>
        <use
          xlinkHref={`${basePath}/rectangle-small-${appTheme}.svg#subservices/web-design/rectangle-small-${appTheme}`}
          href={`${basePath}/rectangle-small-${appTheme}.svg#subservices/web-design/rectangle-small-${appTheme}`}
        />
      </svg>
      <svg className={classNames(styles.rectangle_small_second, styles.animated)}>
        <use
          xlinkHref={`${basePath}/rectangle-small-${appTheme}.svg#subservices/web-design/rectangle-small-${appTheme}`}
          href={`${basePath}/rectangle-small-${appTheme}.svg#subservices/web-design/rectangle-small-${appTheme}`}
        />
      </svg>
      <svg className={classNames(styles.rectangle_filled, styles.animated)}>
        <use
          xlinkHref={`${basePath}/rectangle-small-filled-${appTheme}.svg#subservices/web-design/rectangle-small-filled-${appTheme}`}
          href={`${basePath}/rectangle-small-filled-${appTheme}.svg#subservices/web-design/rectangle-small-filled-${appTheme}`}
        />
      </svg>
      <svg className={styles.content}>
        <use
          xlinkHref={`${basePath}/content-${appTheme}.svg#subservices/web-design/content-${appTheme}`}
          href={`${basePath}/content-${appTheme}.svg#subservices/web-design/content-${appTheme}`}
        />
      </svg>
      <svg className={styles.text}>
        <use
          xlinkHref={`${basePath}/text-${appTheme}.svg#subservices/web-design/text-${appTheme}`}
          href={`${basePath}/text-${appTheme}.svg#subservices/web-design/text-${appTheme}`}
        />
      </svg>
      <svg className={styles.text_right}>
        <use
          xlinkHref={`${basePath}/text-${appTheme}.svg#subservices/web-design/text-${appTheme}`}
          href={`${basePath}/text-${appTheme}.svg#subservices/web-design/text-${appTheme}`}
        />
      </svg>
    </div>
  );
};

export default WebDesign;
