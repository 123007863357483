'use client';

import { SocialCard } from '@sapientpro/sapientpro-data-models';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import styles from './SocialCards.module.scss';
import { Theme, theme } from '../../../store/theme';
import Button from '../../../components/Button';
import Img from '../../../components/Img';

const SocialCardItem = ({ item }: { item: SocialCard }) => {
  const appTheme = useAtomValue(theme);
  const image = appTheme === Theme.LIGHT ? item.light : item.dark;

  return (
    <div className={styles.socialCardItem}>
      <Img
        src={image.url}
        width={96}
        height={96}
        alt={image.alternativeText || item.social}
        className={styles.socialIcon}
      />
      <div className={styles.content}>
        <h5 className={classNames(styles.description, 'subtitle')}>{item.description}</h5>
        <Button
          link={item.link}
          icon={(
            <svg className={styles.linkIcon}>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          )}
          className={styles.socialLink}
        >
          {item.social}
        </Button>
      </div>
    </div>
  );
};

export default SocialCardItem;
