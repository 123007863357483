'use client';

import { RevealingPointsComponent } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import { useSetAtom } from 'jotai';
import styles from './RevealingPoints.module.scss';
import RevealingPoint from './RevealingPoint';
import Button, { ButtonVariants } from '../Button';
import contactModalTriggerButton from '../../store/contactForm';

const RevealingPoints = ({
  data,
  actionButtonText,
  actionButtonVariant = ButtonVariants.OUTLINED,
  contactFormType = 'modal',
}: {
  data: RevealingPointsComponent;
  actionButtonText: string;
  actionButtonVariant?: ButtonVariants;
  contactFormType?: 'section' | 'modal';
}) => {
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  const buttonActionAttr = contactFormType === 'modal'
    ? { onClick: () => setContactsModalTrigger('revealing points section') }
    : { link: '#contact-form' };

  return (
    <section className={classNames(styles.revealingPointsSection, 'container')}>
      <div className={styles.headBlock}>
        <div className={styles.descriptionBlock}>
          <h2 className={classNames(styles.title, 'title3')}>{data.title}</h2>
          {!!data.description && <p className={styles.description}>{data.description}</p>}
        </div>
        <Button
          variant={actionButtonVariant}
          {...buttonActionAttr}
          icon={(
            <svg className={styles.itemIcon}>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
      )}
        >
          {actionButtonText}
        </Button>
      </div>
      <div className={styles.items}>
        {data.points.map(item => (
          <RevealingPoint
            key={item.id}
            item={item}
          />
        ))}
      </div>
    </section>
  );
};

export default RevealingPoints;
