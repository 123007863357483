'use client';

import classNames from 'classnames';
import { useState } from 'react';
import Link from 'next/link';
import { useAtomValue } from 'jotai';
import { RevealingServicesList } from '@sapientpro/sapientpro-data-models';
import styles from './SubservicesList.module.scss';
import WebDesign from './Animations/WebDesign/WebDesign';
import MobileDesign from './Animations/MobileDesign/MobileDesign';
import ProductDesign from './Animations/ProductDesign/ProductDesign';
import deviceScreenSize, { ScreenSize } from '../../../store/deviceScreenSize';
import Redesign from './Animations/Redesign/Redesign';

const Animations: { [key: string]: ({ active }: { active: boolean }) => JSX.Element } = {
  'web design': WebDesign,
  // eslint-disable-next-line quote-props
  'redesign': Redesign,
  'product design': ProductDesign,
  'mobile app design': MobileDesign,
};

const SubserviceListItem = ({ item }: { item: RevealingServicesList }) => {
  const screenSize = useAtomValue(deviceScreenSize);
  const [isActive, setIsActive] = useState<boolean>(false);

  const Animation = Animations[item.title.toLowerCase()];

  const handleActiveChange = (newStatus: boolean, actionType: string) => {
    if (['mouseenter', 'mouseleave'].includes(actionType)) {
      if (screenSize > ScreenSize.TABLET_LANDSCAPE) {
        setIsActive(newStatus);
      }
    } else if (screenSize <= ScreenSize.DESKTOP_SMALL) {
      setIsActive(newStatus);
    }
  };

  const isDisabled = !item.subService.publishedAt;
  return (
    <li>
      <button
        type='button'
        onClick={(e) => handleActiveChange(!isActive, e.type)}
        onMouseEnter={(e) => handleActiveChange(true, e.type)}
        onMouseLeave={(e) => handleActiveChange(false, e.type)}
        className={classNames(styles.itemWrapper, 'container', {
          [styles.active]: isActive,
        })}
      >
        <div className={styles.itemContent}>
          <h2 className={classNames(styles.title, 'title2')}>{item.title}</h2>
          <div className={classNames(styles.animationBlock, styles.mobile)}>
            {!!Animation && <Animation active={isActive} />}
          </div>
          <p className={styles.description}>{item.description}</p>
        </div>
        <div className={styles.animationBlock}>
          {!!Animation && <Animation active={isActive} />}
        </div>
        <Link
          href={`/${item.subService.slug}`}
          onClick={(e) => {
            if (isDisabled) {
              e.preventDefault();
            }
          }}
          className={classNames(styles.iconWrapper, {
            [styles.hidden]: isDisabled,
          })}
        >
          <svg className={styles.arrowIcon}>
            <use
              xlinkHref='/media/icon-nest.svg#iconNest'
              href='/media/icon-nest.svg#iconNest'
            />
          </svg>
        </Link>
      </button>
    </li>
  );
};

export default SubserviceListItem;
