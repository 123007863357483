'use client';

import { AnimatedTeamComponent } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import styles from './TeamSlider.module.scss';
import AnimatedTitle from '../AnimatedTitle';
import TeamMember from './TeamMember';
import { useSectionOffsetScroll } from '../../useSectionOffsetScroll';

const MAX_PHOTO_MARGIN = 96;
const VIEWPORT_PADDING = 300;

const TeamSlider = ({ data }: { data: AnimatedTeamComponent }) => {
  const sliderOffset = useSectionOffsetScroll('team-slider');
  const memberMargin = sliderOffset - VIEWPORT_PADDING >= MAX_PHOTO_MARGIN ? MAX_PHOTO_MARGIN : sliderOffset - VIEWPORT_PADDING;

  const members = data?.team?.members;

  return (
    <section
      className={styles.teamSliderSection}
    >
      <h2 className={classNames(styles.title, 'title3')}><AnimatedTitle title={'BEHIND THE SCENES: \n **MEET OUR TEAM**'} /></h2>
      <Splide
        id='team-slider'
        hasTrack={false}
        options={{
          focus: 'center',
          pagination: false,
          autoWidth: true,
          gap: 32,
          arrows: false,
          breakpoints: {
            1439: {
              gap: 24,
            },
            1279: {
              padding: 16,
            },
            767: {
              padding: 24,
            },
          },
        }}
        className={styles.members}
      >
        <SplideTrack className={styles.membersTrack}>
          {members.map((member, index) => {
            const style = index % 2 !== 0 && sliderOffset > VIEWPORT_PADDING ? { marginTop: `${memberMargin}px` } : {};
            return (
              <SplideSlide style={style}>
                <TeamMember
                  key={member.id}
                  member={member}
                />
              </SplideSlide>
            );
          })}
        </SplideTrack>
      </Splide>
    </section>
  );
};

export default TeamSlider;
