import { IMember } from '@sapientpro/sapientpro-data-models';
import { useRef, useState } from 'react';
import styles from './TeamSlider.module.scss';
import Img from '../Img';
import SocialLink from '../SocialLink';

const TeamMember = ({ member }: { member: IMember }) => {
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const runImagesChange = () => {
    intervalRef.current = setInterval(() => setActiveImageIndex((prev) => {
      if (prev + 1 >= (member?.imagesSet?.length || 0)) return 0;
      return prev + 1;
    }), 500);
  };

  const stopImagesChange = () => {
    intervalRef.current && clearInterval(intervalRef.current);
    setActiveImageIndex(0);
  };

  const image = member?.imagesSet?.[activeImageIndex];

  return (
    <div
      className={styles.teamMember}
      onMouseEnter={runImagesChange}
      onMouseLeave={stopImagesChange}
    >
      {!!image && (
      <Img
        src={image.url}
        width={image.width}
        height={image.height}
        alt={image.alternativeText || 'team member image'}
        className={styles.memberImg}
      />
      )}
      <div className={styles.socials}>
        {!!member.linkedinLink && (
        <SocialLink
          id='linkedinSVG'
          icon='/media/socials/linkedin.svg'
          link={member.linkedinLink}
          className={styles.socialLink}
        />
        )}
        {!!member.behanceLink && (
        <SocialLink
          id='behanceSVG'
          icon='/media/socials/behance.svg'
          link={member.behanceLink}
          className={styles.socialLink}
        />
        )}
      </div>
      <div className={styles.memberInfo}>
        <p className={styles.memberName}>{member.name}</p>
        <p className={styles.memberPosition}>{member.position}</p>
      </div>
    </div>
  );
};

export default TeamMember;
