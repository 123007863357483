'use client';

import { CirclesComponent } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import styles from './CirclesSlider.module.scss';
import deviceScreenSize, { ScreenSize } from '../../store/deviceScreenSize';
import CircleArrowButton from './CircleArrowButton';

const getItemSize = (deviceSize: ScreenSize) => {
  switch (deviceSize) {
    case (ScreenSize.MOBILE): return 242;
    case (ScreenSize.TABLET_PORTRAIT):
    case (ScreenSize.TABLET_LANDSCAPE): return 302;
    default: return 362;
  }
};

const getSliderPadding = (deviceSize: ScreenSize) => {
  switch (deviceSize) {
    case (ScreenSize.MOBILE): return 24;
    case (ScreenSize.TABLET_PORTRAIT): return 64;
    case (ScreenSize.TABLET_LANDSCAPE): return 72;
    case (ScreenSize.DESKTOP_SMALL): return 120;
    case (ScreenSize.DESKTOP): return 200;
    default: return 360;
  }
};

const CirclesSlider = ({ data }: { data: CirclesComponent }) => {
  const deviceSize = useAtomValue(deviceScreenSize);
  const itemSize = getItemSize(deviceSize);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const sliderRef = useRef<Splide>(null);

  const items = data.content;

  const handleActiveItemChange = (newActiveIndex: number) => {
    if (newActiveIndex > -1 && newActiveIndex < items.length) {
      setActiveItemIndex(newActiveIndex);
    }
  };

  const handleDrag = (index: number) => {
    if (window.outerWidth < 768) handleActiveItemChange(index);
  };

  useEffect(() => {
    const nextItem = document.querySelector(`#slider-item-${activeItemIndex}`);
    const nextItemRect = nextItem?.getBoundingClientRect();
    const itemLeftPos = nextItemRect?.left || 0;
    const isOutRight = itemLeftPos > window.innerWidth - itemSize;
    const isOutLeft = itemLeftPos < 0;

    const isInViewport = !isOutLeft && !isOutRight;

    if (deviceSize < ScreenSize.DESKTOP_SMALL || !isInViewport) {
      sliderRef?.current?.go(activeItemIndex);
    }
  }, [activeItemIndex]);

  const scrolledSlider = activeItemIndex > 0;
  const showNextButton = activeItemIndex < items.length - 1;
  const showPrevButton = !!activeItemIndex;

  return (
    <section className={styles.circlesSliderSection}>
      <div className={styles.headBlock}>
        <div className={styles.headContent}>
          <h2 className={styles.title}>{data.title}</h2>
          <p className={styles.description}>{data.description}</p>
        </div>
        <div className={classNames('splide__arrows', styles.navigation)}>
          <CircleArrowButton
            onClick={() => {
              handleActiveItemChange(activeItemIndex - 1);
            }}
            position='prev'
            isActive={showPrevButton}
          />
          <CircleArrowButton
            onClick={() => {
              handleActiveItemChange(activeItemIndex + 1);
            }}
            position='next'
            isActive={showNextButton}
          />
        </div>
      </div>
      <Splide
        hasTrack={false}
        onActive={(e) => handleDrag(e.index)}
        options={{
          arrows: false,
          pagination: false,
          autoWidth: true,
          gap: 24,
          perMove: 1,
          drag: deviceSize < ScreenSize.TABLET_PORTRAIT,
          padding: { left: getSliderPadding(deviceSize) },
        }}
        className={styles.slider}
        ref={sliderRef}
      >
        <SplideTrack>
          {items.map((item, index) => {
            const isActive = index === activeItemIndex;
            const isPrev = index < activeItemIndex;
            const leftShift = isPrev || (isActive && scrolledSlider);

            return (
              <SplideSlide
                key={item.id}
                className={styles.slider}
              >
                <button
                  type='button'
                  key={item.id}
                  id={`slider-item-${index}`}
                  onClick={() => handleActiveItemChange(activeItemIndex > index ? activeItemIndex - 1 : activeItemIndex + 1)}
                  className={classNames(styles.circleItem, {
                    [styles.active]: isActive,
                    [styles.prev]: leftShift && index,
                  })}
                >
                  <div
                    className={styles.contentWrapper}
                    style={{
                      backgroundImage: `url(${item.image1.url}), url(${item.image2.url})`,
                    }}
                  >
                    <span className={styles.content}>
                      {item.text}
                    </span>
                  </div>
                </button>
              </SplideSlide>
            );
          })}
        </SplideTrack>
      </Splide>
    </section>
  );
};

export default CirclesSlider;
