'use client';

import { AccordionComponent } from '@sapientpro/sapientpro-data-models';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classNames from 'classnames';
import { useRef } from 'react';
import styles from './Benefits.module.scss';
import Benefit from './Benefit';
import AnimatedTitle from '../../../components/AnimatedTitle';

const Benefits = ({ data }: { data: AccordionComponent }) => {
  const sliderRef = useRef<Splide>(null);
  const handleScrollTo = (index: number) => {
    sliderRef.current?.go(index);
  };

  return (
    <section className={styles.benefitsSection}>
      <h2 className={classNames(styles.title, 'title3')}><AnimatedTitle title={data.title} /></h2>
      <Splide
        hasTrack={false}
        options={{
          arrows: false,
          pagination: false,
          autoWidth: true,
          gap: 16,
          drag: true,
          perMove: 1,
          padding: { left: 320, right: 640 },
          breakpoints: {
            1919: {
              padding: { left: 200, right: 400 },
            },
            1439: {
              padding: { left: 120, right: 240 },
            },
            1279: { padding: { left: 72, right: 144 } },
            1023: { padding: { left: 64, right: 127 } },
            767: { padding: { left: 24, right: 48 } },
          },
        }}
        className={styles.slider}
        ref={sliderRef}
      >
        <SplideTrack>
          {data.content.map((item, index) => (
            <SplideSlide>
              <Benefit
                key={item.id}
                item={item}
                onActive={() => handleScrollTo(index)}
              />
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    </section>
  );
};

export default Benefits;
