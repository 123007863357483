'use client';

import { TitleDescription } from '@sapientpro/sapientpro-data-models';
import { useState } from 'react';
import classNames from 'classnames';
import styles from './RevealingPoints.module.scss';

const RevealingPoint = ({ item }: { item: TitleDescription }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  return (
    <button
      type='button'
      onClick={() => setIsActive(!isActive)}
      className={classNames(styles.item, {
        [styles.active]: isActive,
      })}
    >
      <div className={styles.itemContent}>
        <p
          className={classNames(styles.question, 'title6')}
        >
          {item.title}
        </p>
        <p className={styles.answer}>
          {item.description}
        </p>
      </div>
      <svg className={styles.itemIcon}>
        <use
          xlinkHref='/media/icon-nest.svg#iconNest'
          href='/media/icon-nest.svg#iconNest'
        />
      </svg>
    </button>
  );
};

export default RevealingPoint;
