import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import styles from './CirclesSlider.module.scss';
import Img from '../Img';
import { theme } from '../../store/theme';

interface ICircleArrowProps {
  onClick: () => void;
  position: 'prev' | 'next';
  isActive: boolean;
}

const CircleArrowButton = ({ onClick, position, isActive }: ICircleArrowProps) => {
  const appTheme = useAtomValue(theme);

  const defaultUrl = `/media/slider-arrow-right-${appTheme}.svg`;
  const hoveredUrl = `/media/slider-arrow-right-hovered-${appTheme}.svg`;
  const pressedUrl = `/media/slider-arrow-right-pressed-${appTheme}.svg`;

  const [arrowSrc, setArrowSrc] = useState<string>(defaultUrl);
  const subClass = position === 'prev' ? styles.arrow_prev : styles.arrow_next;

  useEffect(() => {
    setArrowSrc(defaultUrl);
  }, [appTheme]);

  return (
    <button
      onClick={onClick}
      type='button'
      className={classNames(`splide__arrow splide__arrow--${position}`, styles.arrow, subClass, {
        [styles.visible]: isActive,
      })}
      onMouseEnter={() => setArrowSrc(hoveredUrl)}
      onMouseLeave={() => setArrowSrc(defaultUrl)}
      onMouseDown={() => setArrowSrc(pressedUrl)}
    >
      <Img
        src={arrowSrc}
        alt='slider arrow right'
      />
    </button>
  );
};

export default CircleArrowButton;
