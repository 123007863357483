import { CaseListItem, ICaseResponse } from '@sapientpro/sapientpro-data-models';
import Link from 'next/link';
import React from 'react';
import classNames from 'classnames';
import AnimatedTitle, { AnimationType } from '../../../../components/AnimatedTitle';
import Img from '../../../../components/Img';
import styles from './CaseItem.module.scss';
import CustomCursorWrapper from '../../../../components/CustomCursor/CustomCursorWrapper';

interface ICaseItemProps {
  caseItem: CaseListItem | ICaseResponse,
  view?: 'default' | 'detailed' | 'alternative',
  withPreviewImage?: boolean;
}

const CaseItem = ({ caseItem, view = 'default', withPreviewImage }: ICaseItemProps) => {
  const {
    subservices, slug, projectName,
  } = caseItem;
  const isAlternative = view === 'alternative';
  const showSubservices = !!subservices && !!subservices.length && !isAlternative;

  const image = caseItem.previewImage || caseItem.image;
  const description = caseItem.previewDescription || caseItem.description;
  const title = caseItem.previewTitle || caseItem.title;

  const displayedImage = withPreviewImage ? image : caseItem.image;

  return (
    <div className={classNames(styles.case, styles[`${view}-view`])}>
      {showSubservices && (
        <div className={styles.categories}>
          {subservices.map(subservice => (
            <Link
              href={`/${subservice.slug}`}
              className={styles.category}
              key={subservice.id}
            >
              .
              <AnimatedTitle
                animationType={AnimationType.NONE}
                title={subservice.title}
              />
            </Link>
          ))}
        </div>
      )}
      {isAlternative ? (
        <CustomCursorWrapper
          cursorText='View'
          view='light'
        >
          <Link
            href={`/cases/${slug}`}
            passHref
            className={classNames(styles.image, styles[`${view}-view`], {
              [styles.withCustomCursor]: isAlternative,
            })}
          >
            <Img
              src={displayedImage ? displayedImage?.url : '/'}
              alt={displayedImage.alternativeText || caseItem.slug}
              withPreloader
            />
          </Link>
        </CustomCursorWrapper>
      ) : (
        <Link
          href={`/cases/${slug}`}
          passHref
          className={styles.image}
        >
          <Img
            src={displayedImage ? displayedImage?.url : '/'}
            alt={displayedImage.alternativeText || caseItem.slug}
            withPreloader
          />
        </Link>
      )}

      {isAlternative && (
      <div className={styles.caseInfo}>
        <p className={styles.caseProject}>{projectName}</p>
        <h5 className={styles.caseTitle}>{title}</h5>
        <p
          className={styles.caseDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      )}
    </div>
  );
};

export default CaseItem;
