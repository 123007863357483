import { AccordionContent } from '@sapientpro/sapientpro-data-models';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import styles from './Benefits.module.scss';
import { Theme, theme } from '../../../store/theme';
import Img from '../../../components/Img';
import deviceScreenSize, { ScreenSize } from '../../../store/deviceScreenSize';

const Benefit = ({ item, onActive }: { item:AccordionContent; onActive: () => void }) => {
  const appTheme = useAtomValue(theme);
  const deviceSize = useAtomValue(deviceScreenSize);
  const [isActive, setIsActive] = useState<boolean>(false);
  const itemRef = useRef<HTMLButtonElement>(null);

  const image = appTheme === Theme.DARK ? item.darkImage : item.lightImage;
  const isMobile = deviceSize === ScreenSize.MOBILE;

  return (
    <button
      type='button'
      className={classNames(styles.benefitItem, {
        [styles.active]: isActive || isMobile,
      })}
      onClick={() => {
        setIsActive(!isActive);
        !isActive && onActive();
      }}
      ref={itemRef}
    >
      <Img
        src={image.url}
        width={60}
        height={60}
        alt={image.alternativeText || 'benefit image'}
      />
      <div className={styles.content}>
        <p className={classNames(styles.description, 'bodyText')}>{item.description}</p>
        <h6 className={classNames(styles.itemTitle, 'title6')}>{item.title}</h6>
      </div>
    </button>
  );
};

export default Benefit;
