'use client';

import { BlogSpecificPostsComponent } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import styles from './ServicePosts.module.scss';
import Button from '../../../components/Button';
import ServicePost from './ServicePost';

const ServicePosts = ({ data }: { data: BlogSpecificPostsComponent }) => (
  <section className={styles.postsSection}>
    <header>
      <h2 className={classNames(styles.title, 'title3')}>{data.title}</h2>
      <Button
        link='/blog'
        icon={(
          <svg className={styles.icon}>
            <use
              xlinkHref='/media/icon-nest.svg#iconNest'
              href='/media/icon-nest.svg#iconNest'
            />
          </svg>
          )}
        className={styles.headerButton}
      >
        view all
      </Button>
    </header>
    <div className={styles.posts}>
      {data.blog_posts.map(post => (
        <ServicePost
          key={post.id}
          post={post}
        />
      ))}
    </div>
  </section>
);

export default ServicePosts;
