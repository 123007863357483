import {
  ComponentProps,
  ReactNode, useEffect, useRef, useState,
} from 'react';
import styles from './CustomCursor.module.scss';
import CustomCursor from './CustomCursor';

interface ICustomCursorWrapperProps {
  children: ReactNode;
  cursorText: string;
  view?: ComponentProps<typeof CustomCursor>['view'] ;

}

const CustomCursorWrapper = ({ children, cursorText, view }: ICustomCursorWrapperProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const moveCursor = (e: MouseEvent) => {
      const mouseY = e.clientY;
      const mouseX = e.clientX;
      const cursor = document.querySelector('#custom-cursor-block') as HTMLDivElement;

      if (cursor) {
        cursor.style.top = `${mouseY - 48}px`;
        cursor.style.left = `${mouseX - 48}px`;
      }
    };

    wrapperRef.current?.addEventListener('mousemove', moveCursor);
    wrapperRef.current?.addEventListener('mouseover', () => setIsActive(true));
    wrapperRef.current?.addEventListener('mouseleave', () => setIsActive(false));

    return () => {
      wrapperRef.current?.removeEventListener('mousemove', moveCursor);
      wrapperRef.current?.removeEventListener('mouseover', () => setIsActive(true));
      wrapperRef.current?.removeEventListener('mouseleave', () => setIsActive(false));
    };
  }, []);
  return (
    <div
      className={styles.customCursorWrapper}
      ref={wrapperRef}
    >
      {children}
      {isActive && (
      <CustomCursor
        text={cursorText}
        view={view}
      />
      )}
    </div>
  );
};

export default CustomCursorWrapper;
