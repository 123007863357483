import { BlogSpecificPostData } from '@sapientpro/sapientpro-data-models';
import Link from 'next/link';
import styles from './ServicePosts.module.scss';
import Img from '../../../components/Img';
import AnimatedTitle from '../../../components/AnimatedTitle';

const ServicePost = ({ post }: { post: BlogSpecificPostData }) => (
  <div className={styles.servicePost}>
    <Img
      src={post.backgroundImage.url}
      width={post.backgroundImage.width}
      height={post.backgroundImage.height}
      alt={post.backgroundImage.alternativeText || post.title}
      className={styles.postImg}
    />
    <div className={styles.content}>
      <h6 className={styles.title}>
        <Link href={`blog/${post.slug}`}>
          <AnimatedTitle title={post.title} />
        </Link>
      </h6>
      <p className={styles.info}>
        <Link href={`blog/author/${post?.author?.slug}`}>{post?.author?.pseudonym}</Link>
        <span className={styles.infoDivider}>/</span>
        {post.publishDate}
      </p>
    </div>
  </div>
);

export default ServicePost;
