import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import styles from './ProductDesign.module.scss';
import { theme } from '../../../../../store/theme';

const basePath = '/media/subservice-animations/product-design';

const ProductDesign = ({ active }: { active: boolean }) => {
  const appTheme = useAtomValue(theme);

  return (
    <div className={classNames(styles.wrapper, {
      [styles.active]: active,
    })}
    >
      <svg className={styles.left_block}>
        <use
          xlinkHref={
          `${basePath}/left-block-${appTheme}.svg#subservices/product-design/left-block-${appTheme}`
        }
          href={`${basePath}/left-block-${appTheme}.svg#subservices/product-design/left-block-${appTheme}`}
        />
      </svg>
      <svg className={styles.center_block}>
        <use
          xlinkHref={
          `${basePath}/center-block-${appTheme}.svg#subservices/product-design/center-block-${appTheme}`
        }
          href={
          `${basePath}/center-block-${appTheme}.svg#subservices/product-design/center-block-${appTheme}`
        }
        />
      </svg>
      <svg className={styles.right_block}>
        <use
          xlinkHref={
          `${basePath}/right-block-${appTheme}.svg#subservices/product-design/right-block-${appTheme}`
        }
          href={
            `${basePath}/right-block-${appTheme}.svg#subservices/product-design/right-block-${appTheme}`
          }
        />
      </svg>
    </div>
  );
};

export default ProductDesign;
