'use client';

import classNames from 'classnames';
import styles from './CustomCursor.module.scss';

interface IProps {
  text: string;
  view?: 'light' | 'dark';
}

const CustomCursor = ({ text, view = 'dark' }: IProps) => (
  <div
    className={classNames(styles.customCursor, styles[view])}
    id='custom-cursor-block'
    aria-disabled
  >
    {text}
  </div>
);

export default CustomCursor;
