import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import styles from './MobileDesign.module.scss';
import { theme } from '../../../../../store/theme';

const basePath = '/media/subservice-animations/mobile-design';

const MobileDesign = ({ active }: { active: boolean }) => {
  const appTheme = useAtomValue(theme);

  return (
    <div className={classNames(styles.wrapper, {
      [styles.active]: active,
    })}
    >
      <svg className={styles.phone_crossed}>
        <use
          xlinkHref={
          `${basePath}/phone-crossed-${appTheme}.svg#subservices/mobile-design/phone-crossed-${appTheme}`
        }
          href={
          `${basePath}/phone-crossed-${appTheme}.svg#subservices/mobile-design/phone-crossed-${appTheme}`
        }
        />
      </svg>
      <svg className={classNames(styles.phone_left, styles.animated)}>
        <use
          xlinkHref={`${basePath}/phone-left-${appTheme}.svg#subservices/mobile-design/phone-left-${appTheme}`}
          href={`${basePath}/phone-left-${appTheme}.svg#subservices/mobile-design/phone-left-${appTheme}`}
        />
      </svg>
      <svg className={classNames(styles.phone_right, styles.animated)}>
        <use
          xlinkHref={
          `${basePath}/phone-right-${appTheme}.svg#subservices/mobile-design/phone-right-${appTheme}`
        }
          href={`${basePath}/phone-right-${appTheme}.svg#subservices/mobile-design/phone-right-${appTheme}`}
        />
      </svg>
      <svg className={classNames(styles.phone_text_top, styles.animated)}>
        <use
          xlinkHref={
          `${basePath}/phone-text-top-${appTheme}.svg#subservices/mobile-design/phone-text-top-${appTheme}`
        }
          href={
          `${basePath}/phone-text-top-${appTheme}.svg#subservices/mobile-design/phone-text-top-${appTheme}`
        }
        />
      </svg>
      <svg className={classNames(styles.phone_text_bottom, styles.animated)}>
        <use
          xlinkHref={
          `${basePath}/phone-text-bottom-${appTheme}.svg#subservices/mobile-design/phone-text-bottom-${appTheme}`
        }
          href={
          `${basePath}/phone-text-bottom-${appTheme}.svg#subservices/mobile-design/phone-text-bottom-${appTheme}`
        }
        />
      </svg>
      <svg className={styles.rectangle_right}>
        <use
          xlinkHref={
          `${basePath}/rectangle-right-${appTheme}.svg#subservices/mobile-design/rectangle-right-${appTheme}`
        }
          href={
          `${basePath}/rectangle-right-${appTheme}.svg#subservices/mobile-design/rectangle-right-${appTheme}`
        }
        />
      </svg>
      <svg className={styles.rectangle_left}>
        <use
          xlinkHref={
          `${basePath}/rectangle-left-${appTheme}.svg#subservices/mobile-design/rectangle-left-${appTheme}`
        }
          href={
          `${basePath}/rectangle-left-${appTheme}.svg#subservices/mobile-design/rectangle-left-${appTheme}`
        }
        />
      </svg>
      <svg className={classNames(styles.rectangle_crossed, styles.animated)}>
        <use
          xlinkHref={
          `${basePath}/rectangle-crossed-${appTheme}.svg#subservices/mobile-design/rectangle-crossed-${appTheme}`
        }
          href={
          `${basePath}/rectangle-crossed-${appTheme}.svg#subservices/mobile-design/rectangle-crossed-${appTheme}`
        }
        />
      </svg>
    </div>
  );
};

export default MobileDesign;
