import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import styles from './Redesign.module.scss';
import { theme } from '../../../../../store/theme';

const basePath = '/media/subservice-animations/redesign';

const Redesign = ({ active }: { active: boolean }) => {
  const appTheme = useAtomValue(theme);

  return (
    <div className={classNames(styles.wrapper, {
      [styles.active]: active,
    })}
    >
      <svg className={styles.window}>
        <use
          href={`${basePath}/main-window-${appTheme}.svg#subservices/redesign/main-window-${appTheme}`}
          xlinkHref={`${basePath}/main-window-${appTheme}.svg#subservices/redesign/main-window-${appTheme}`}
        />
      </svg>
      <svg className={styles.side_content}>
        <use
          href={`${basePath}/side-content-${appTheme}.svg#subservices/redesign/side-content-${appTheme}`}
          xlinkHref={`${basePath}/side-content-${appTheme}.svg#subservices/redesign/side-content-${appTheme}`}
        />
      </svg>
      <svg className={styles.filled_rectangle}>
        <use
          href={`${basePath}/filled-rectangle-${appTheme}.svg#subservices/redesign/filled-rectangle-${appTheme}`}
          xlinkHref={`${basePath}/filled-rectangle-${appTheme}.svg#subservices/redesign/filled-rectangle-${appTheme}`}
        />
      </svg>
      <svg className={styles.framed_cube}>
        <use
          href={`${basePath}/framed-cube-${appTheme}.svg#subservices/redesign/framed-cube-${appTheme}`}
          xlinkHref={`${basePath}/framed-cube-${appTheme}.svg#subservices/redesign/framed-cube-${appTheme}`}
        />
      </svg>
      <svg className={classNames(styles.crossed_squire, styles.first)}>
        <use
          href={`${basePath}/crossed-squire-${appTheme}.svg#subservices/redesign/crossed-squire-${appTheme}`}
          xlinkHref={`${basePath}/crossed-squire-${appTheme}.svg#subservices/redesign/crossed-squire-${appTheme}`}
        />
      </svg>
      <svg className={classNames(styles.crossed_squire, styles.second)}>
        <use
          href={`${basePath}/crossed-squire-${appTheme}.svg#subservices/redesign/crossed-squire-${appTheme}`}
          xlinkHref={`${basePath}/crossed-squire-${appTheme}.svg#subservices/redesign/crossed-squire-${appTheme}`}
        />
      </svg>
      <svg className={classNames(styles.crossed_squire, styles.third)}>
        <use
          href={`${basePath}/crossed-squire-${appTheme}.svg#subservices/redesign/crossed-squire-${appTheme}`}
          xlinkHref={`${basePath}/crossed-squire-${appTheme}.svg#subservices/redesign/crossed-squire-${appTheme}`}
        />
      </svg>
      <svg className={styles.text_small}>
        <use
          href={`${basePath}/text-small-${appTheme}.svg#subservices/redesign/text-small-${appTheme}`}
          xlinkHref={`${basePath}/text-small-${appTheme}.svg#subservices/redesign/text-small-${appTheme}`}
        />
      </svg>
      <svg className={styles.text_large}>
        <use
          href={`${basePath}/text-large-${appTheme}.svg#subservices/redesign/text-large-${appTheme}`}
          xlinkHref={`${basePath}/text-large-${appTheme}.svg#subservices/redesign/text-large-${appTheme}`}
        />
      </svg>
    </div>
  );
};

export default Redesign;
